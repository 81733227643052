<template>
  <v-container>
    <div class="d-flex justify-space-between">
      <div>
        <v-btn icon @click="$router.go(-1)"><v-icon>arrow_back</v-icon></v-btn>
        <span class="student-name">{{ studentName }}</span>
      </div>
      <div>
        <v-btn
          v-if="asses"
          color="primary"
          outlined
          class="mr-5"
          @click="asses = false"
          >{{ !$language ? "Cancel" : "বাতিল করুন" }}</v-btn
        >
        <v-btn v-else color="primary" outlined class="mr-5" @click="download"
          >{{!$language ? "Download" : "ডাউনলোড"}}</v-btn
        >
        <v-btn v-if="asses" color="primary" :loading="saveProp" @click="save"
          >{{!$language ? "Save" : "সেভ করুন"}}</v-btn
        >
        <v-btn v-else color="primary" @click="asses = !asses">{{!$language ? "Assesment" : "মূল্যায়ন"}}</v-btn>
      </div>
    </div>
    <v-row v-if="loading" justify="center">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </v-row>
    <pdf-renderer
      v-else-if="docUrl"
      :url="docUrl"
      :asses="asses"
      :save="saveProp"
      :s3-key="s3Key"
      class="mt-5"
      @save-done="onSave"
    ></pdf-renderer>
    <p v-else class="text-center">...</p>
  </v-container>
</template>

<script>
import projectworkService from "#ecf/project/services/ProjectWorkService";
import PdfRenderer from "#ecf/assessment/components/PdfRenderer.vue";
export default {
  name: "ProjectAssessmentPage",
  components: { PdfRenderer },
  props: {
    projectId: String,
    studentId: String,
    index: String
  },
  data() {
    return {
      loading: true,
      docUrl: "",
      asses: false,
      s3Key: "",
      saveProp: false,
      ext: ""
    };
  },
  computed: {
    submissionType() {
      return this.index.split("-")[0];
    },
    submissionIndex() {
      return Number(this.index.split("-")[1]);
    },
    studentName() {
      return this.loading
        ? "..."
        : this.$store.state.student.studentMap[this.$route.params.courseId]
            .users[this.studentId];
    }
  },
  mounted() {
    
    this.fetchSubmission();
  },
  methods: {
    async fetchSubmission() {
      try {
        await this.$store.dispatch("student/list", {
          courseId: this.$route.params.courseId,
          partner_code: this.$route.params.partner_code
        });
        let docs = await projectworkService.getSubmission(
          this.$route.params.courseId,
          this.projectId,
          this.studentId
        );
        docs =
          this.submissionType == "whole"
            ? docs.submissions[0]
            : docs.step_submissions[Number(this.submissionType.slice(4))];
        let mainUrl = docs.url[Number(this.submissionIndex)].split("?")[0];
        let slashIndex = mainUrl.indexOf("s3.amazonaws.com/");
        this.s3Key = decodeURIComponent(mainUrl.slice(slashIndex + 17));
        this.ext = this.s3Key.split(".").pop();
        let url;
        if (docs.assessed_url[Number(this.submissionIndex)].length > 0) {
          // file previously assessed
          url = docs.assessed_url[Number(this.submissionIndex)];
        } else if (
          docs.converted_url[Number(this.submissionIndex)].length > 0
        ) {
          // file not assessed but converted for assessment
          url = docs.converted_url[Number(this.submissionIndex)];
        } else if (this.ext == "pdf") {
          // pdf don't need conversion
          url = docs.url[Number(this.submissionIndex)];
        } else {
          // old file
          this.$root.$emit("alert", [undefined, "Can't assess this file"]);
          return;
        }
        this.docUrl = url;
      } catch (e) {
        window.location.reload();
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.loading = false;
      }
    },
    download() {
      if (this.docUrl) window.open(this.docUrl);
    },
    save() {
      this.saveProp = true;
      this.fetchSubmission();
    },
    onSave() {
      this.saveProp = false;
      this.asses = false;
      this.fetchStudentAndSubmission();
    }
  }
};
</script>

<style></style>
